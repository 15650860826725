@import './keyframes.scss';
// @import './mixins.scss';

.eventPoll-grid-item {
    // max-width: 100%;
    display: flex;
    justify-content: center;
    // background-color: $bgColor1;
    // width: 25vw;
    // margin: 17vh 17vw;
    border-radius: 5%;
    padding-bottom: 50px;

    .eventPoll-grid-item-details {
        // margin: 0 50%;
        max-width: 88%;
        border: 1px solid black;
    }
}

.react-multiple-carousel__arrow {
    bottom: 0;
    background-color: $bgColor2 !important;
}

.event-polls-carousel {
    justify-content: center;

    @include for-mobile {
        justify-content: unset;
    }
}

// .react-multi-carousel-list {
//     min-height: 50vh;
// }
