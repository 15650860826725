/* Общие стили для всех устройств */
div.adaptive {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f8f8f8;
    color: #333;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
}

/* Стили для мобильных устройств */
@media (max-width: 767px) {
    div.adaptive {
        padding: 10px;
        margin: 5px;
        font-size: 14px;
    }
}

/* Стили для планшетов */
@media (min-width: 768px) and (max-width: 1023px) {
    div.adaptive {
        padding: 15px;
        margin: 8px;
        font-size: 15px;
    }
}

/* Стили для десктопов */
@media (min-width: 1024px) {
    div.adaptive {
        padding: 20px;
        margin: 10px;
        font-size: 16px;
    }
}
