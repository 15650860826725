@mixin circleItem {
    clip-path: circle(50%);
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.5, 1.5);
    }

    100% {
        transform: scale(1, 1);
    }
}

@keyframes zoom-text {
    0% {
        font-size: 0.4em;
    }

    50% {
        font-size: 0.5em;
    }

    100% {
        font-size: 0.4em;
    }
}

@keyframes text-dynamic-color {
    0% {
        background-color: rgb(321, 321, 231);
    }

    50% {
        background-color: rgb(321, 321, 123);
    }

    100% {
        background-color: rgb(321, 321, 231);
    }
}