@import './palette.scss';
@import './mixins.scss';
@import './userInfo.scss';
@import './eventPoll.scss';
@import './user.scss';
@import './about.scss';
@import './adaptive.scss';

body {
    position: relative;

    &::before {
        content: '';
        content: "";
        position: fixed;
        /* Закрепленный фон */
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('../images/dnk_spiral_bg.webp') repeat;
        background-size: 46px 46px;
        opacity: 0.2;
        z-index: -1;
    }
}


.App {
    text-align: center;
    min-height: 100%;
}

.header-bar {
    // background-image: url('../images/star_space.png') !important;
    background-color: transparent !important;
    color: black;
    margin-bottom: 15px;

    a {
        color: #000;
        text-decoration: none;
        font-weight: 200;

        &:visited {
            color: #000;
        }
    }
}

.footer-bar {
    color: white;
    background-color: transparent !important;
    // background-image: url('../images/star_space.png');
    // background-position: center;
    // background-size: cover;
}

.app-menu {
    position: static;
    margin-left: 3vw;
    margin-top: 5vh;
    width: 100px;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}

.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: green transparent green transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}

.form-group,
.create-form-group {
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';

    div {
        margin: 10px auto;
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

a {
    color: $linkColor;
    text-decoration: none;
    font-weight: 200;

    &:visited {
        color: $linkColor;
    }
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media (max-width: 1360px) {
    // #root {
    //     display: none;
    // }
    // body::before {
    // content: 'Разработка версии для мобильных устройств в процессе. Предлагаю использовать desktop-версию.';
    // }

}