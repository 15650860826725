@import './palette.scss';

.item-as-link {
    &:hover {
        cursor: pointer;
        background: $hoverBackgroundColor;
    }
}

.club-game-event-date-picker {
    width: 100%;
}

.table-grid-item {
    &:nth-child(odd) {
        background: $gridItemBackgroundColor;
    }
}

.avatar-image {
    height: 60px;
    width: 60px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
}

@media (max-width: 700px) {
    .app-bar-container {
        flex-direction: column;
    }

    .add-club-member {
        flex-direction: column;
        align-items: center;

        .button {
            margin-top: 5px;
        }
    }
}