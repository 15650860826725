$mobile: 767px;
$tablet: 1024px;
$desktop: 1025px;

// Миксин для мобильных устройств
@mixin for-mobile {
  @media (max-width: $mobile) {
    @content;
  }
}
