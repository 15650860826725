$linkColor:  #4E513B;
$hoverBackgroundColor: rgb(162,162,208, 0.1);
$gridItemBackgroundColor: rgb(162,162,208, 0.1);
$borderColor: rgb(208, 241, 247);
$greenBorderColor: rgb(121, 223, 53);
$activeColor: rgb(208, 241, 247, 0.6);

$bgColor1: rgb(240, 240, 184, 0.2);
$bgColor2: rgb(220, 240, 184, 0.9);
$bgColor3: rgb(240, 240, 294, 1);
